import { graphql, navigate, PageProps } from 'gatsby';
import 'twin.macro';
import BaseLayout from '../layouts/base';
import { GetTagDataQuery } from '../graphql-types';
import { Helmet } from 'react-helmet';
import React from 'react';
import SearchForm from '../components/search-form';
import { PageContext, Pagination } from '../components/pagination';
import SearchItem from '../components/search-item';
import { generateTag } from '../utils/helper';

const Tag: React.FC<PageProps<GetTagDataQuery>> = ({ data, location }) => {
  const pageInfo = data.allContentfulArtikel.pageInfo;
  const pageContext: PageContext = {
    currentPage: pageInfo.currentPage,
    hasNextPage: pageInfo.hasNextPage,
    hasPreviousPage: pageInfo.hasPreviousPage,
    pageCount: pageInfo.pageCount,
    perPage: pageInfo.perPage,
    totalCount: pageInfo.totalCount,
  };

  const logo = data?.logo?.childrenImageSharp[0]?.fluid;

  // SEO data
  const siteMeta = data.site?.siteMetadata;
  const canonical = `${siteMeta.url}${location.pathname}`;

  const articles = data.allContentfulArtikel.edges
    .map(a => a.node)
    .map(ar => {
      return {
        title: ar?.title,
        date: ar?.date,
        info: ar?.info,
        image: ar?.img?.file.url,
        link: '/' + ar?.uid,
        category: ar?.category,
        description: ar?.shortDescription?.raw,
      };
    });
  const tag = data.allContentfulTagsTagsJsonNode?.nodes[0]?.title;

  const seo = data.allContentfulSeoHeader.nodes[0];
  const desc =
    generateTag(
      seo?.description?.description,
      {
        name: tag,
      },
      pageContext,
    ) || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta';
  const title = generateTag(seo?.title, { name: tag }) || `${tag} - Easybiz`;

  const jsonLd = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        url: canonical,
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${siteMeta.url}/search/?query={search_term_string}`,
          },
          'query-input': 'required name=search_term_string',
        },
      },
    ],
  };

  function handleSearch(query: string) {
    navigate(`/search/?query=${query ?? ''}`);
  }

  return (
    <BaseLayout logo={logo} pathname={location.pathname} fixedHeader={true}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content={seo?.keyword || tag} />
        <meta
          name="robots"
          content={
            seo?.robots ||
            'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
          }
        />
        <link rel="canonical" href={canonical} />
        <meta name="author" content={seo?.author || 'Easybiz'} />
        <meta name="publisher" content={seo?.publisher || 'Easybiz'} />
        <meta property="og:locale" content={seo?.oglocale || 'en_US'} />
        <meta property="og:type" content={seo?.ogtype || 'website'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={`${siteMeta.url}/tag/${tag}`} />
        <meta property="og:site_name" content="Easybiz" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`${siteMeta.url}/tag/${tag}`} />
        <meta name="twitter:creator" content={seo?.author || 'Easybiz'} />
        <meta name="twitter:description" content={desc} />
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
      <section tw="bg-white dark:bg-black-200">
        <div tw="container">
          <div tw="mx-8 lg:mx-32 mt-28 lg:mt-16">
            <div tw="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 py-4">
              <div tw="lg:col-span-2">
                <SearchForm query={''} onSubmit={query => handleSearch(query)} />
                <div>
                  <h1 tw="text-base font-normal my-8 dark:text-white">Menampilkan hasil pencarian "{tag}"</h1>
                  {articles.map((article, idx) => (
                    <div key={'article-result-' + idx}>
                      <SearchItem key={article.link} {...article} />
                    </div>
                  ))}
                  <Pagination
                    pageContext={pageContext}
                    route={`tag/${tag?.toLocaleLowerCase()}`}
                    pageRoute={`page`}
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default Tag;

export const query = graphql`
  query getTagData($tag: String!, $limit: Int!, $skip: Int!) {
    allContentfulTagsTagsJsonNode(filter: { title: { eq: $tag } }) {
      nodes {
        title
      }
    }
    allContentfulSeoHeader(filter: { identifier: { eq: "tag" } }) {
      nodes {
        title
        description {
          description
        }
        keyword
        language
        robots
        author
        publisher
        updatedAt
        oglocale
        ogtype
      }
    }
    allContentfulArtikel(
      filter: { tags: { eq: $tag } }
      limit: $limit
      skip: $skip
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        uid
      }
      edges {
        node {
          uid
          title
          category
          tags
          shortDescription {
            raw
          }
          author
          info
          date
          img {
            file {
              url
              fileName
            }
            fluid(maxHeight: 70, maxWidth: 125) {
              ...ContentfulImageFluidFields
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
